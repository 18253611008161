<template>
  <div class="BzOb_Loved noselect" v-if="(products > 0 || windowWidth <= 768)">
    <router-link to="/oblibene">
    <img src="../../assets/love_white.svg" v-if="(products == 0 || windowWidth <= 768) && theme == 'white'" />
    <img src="../../assets/love_white_n.svg" v-if="(products > 0 && products < 10) && theme == 'white'" />
    <img src="../../assets/love_white_m.svg" v-if="(products >= 10) && theme == 'white'" />
    <img src="../../assets/love_base.svg" v-if="(products == 0 || windowWidth <= 768) && theme == 'base'" />
    <img src="../../assets/love_base_n.svg" v-if="(products > 0 && products < 10) && theme == 'base'" />
    <img src="../../assets/love_base_m.svg" v-if="(products >= 10) && theme == 'base'" />
    <p class="BzObLd_Counter1 noselect" v-if="products > 0 && products < 10">{{products_c}}</p>
    <p class="BzObLd_Counter2 noselect" v-if="products >= 10">{{products_c}}</p>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'NavigationAccount',
  props: {
    theme: String,
    windowWidth: Number,
    products: Number
  },
  data() {
    return {
    }
  },
  methods: {
    get_loved_products: function() {

    }
  }, 
  computed: {
    products_c() {
      if (this.products < 99)
        return this.products;
      else
        return "++";
    }
  }
}
</script>

<style scoped>
img {
  height: 48px;
  pointer-events: none;
}
.BzOb_Loved {
  width: 48px;
  position:relative;
  margin: 12px;
}
@media screen and (max-width:768px) {
img {
  height: 36px;
  pointer-events: none;
}
.BzOb_Loved {
  width: 100%;
  position:relative;
  height: 36px;
  margin: 0px;
  text-align: center;
}
}
.BzObLd_Counter1 {
  position: absolute;
  font-weight: bold;
  top: -0.7px;
  right: -0.5px;
  font-size: 14px;
  color: white;
  text-align: center;
  width: 18.7px;
  height: 18.7px;
}
.BzObLd_Counter2 {
  position: absolute;
  font-weight: bold;
  top: -0.7px;
  right: -0.5px;
  font-size: 14px;
  color: white;
  text-align: center;
  width: 26px;
  height: 18.7px;
}
</style>