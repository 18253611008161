<template>
  <footer>
    <div class="BzObFt_Banner" v-if="type != 'bannerless'">
      <div class="columns is-variable is-8 is-vcentered fh">
        <div class="column is-narrow">
          <img src="../../assets/sleva.svg" class="BzObFtBn_SaleTag" />
          <div class="BzObFtBn_SaleTagFiller"></div>
        </div>
        <div class="column">
          <div class="BzObFtBn_Top">Získejte 5% slevu na další nákup!</div>
          <div class="BzObFtBn_Bottom">Staňte se členy našeho věrnostního programu</div>
        </div>
        <div class="column is-narrow">
          <b-button type="is-link is-inverted" size="is-large" class="has-text-weight-bold">...Více informací</b-button>
        </div>
      </div>
    </div>
    <div class="BzObFt_Lvl1" :class="type">
      <div class="columns">
        <div class="column has-text-grey">
          <img src="../../assets/pooltechnik.svg" />
          <address class="address">
            <p>Dělnická 782</p>
            <p>506 01 Jičín</p>
          </address>
          <div class="Spacer"></div>
          <b>+420 491 202 220<br>
            <a class="has-text-grey" href="mailto:info@pooltechnik.cz">info@pooltechnik.cz</a></b>

        </div>
        <div class="column is-narrow">
          <div class="vdiv"></div>
        </div>
        <div class="column" v-for="link in links" :key="link.title">
          <p class="BzObFtLvl_Title">{{link.title}}</p>
          <ul>
            <router-link :to="child.url" tag="li" class="clickable" v-for="child in link.children" :key="child.title">
              {{child.title}}
            </router-link>
          </ul>
        </div>
      </div>
      <div class="hdiv"></div>
    </div>
    <div class="BzObFt_Lvl2">
      <p class="BzObFtLvl2_Text">Texty na tomto serveru jsou chráněny autorským zákonem. Jejich další šíření bez
        souhlasu autora je zakázané.</p>
      <p class="BzObFtLvl2_Text">Podle zákona o evidenci tržeb je prodávající povinen vystavit kupujícímu účtenku,
        zároveň je povinen zaevidovat přijatou
        tržbu u správce daně on-line; v případě technického výpadku pak nejpozději do 48 hodin</p>
      <p class="BzObFtLvl2_Foot">
        COPYRIGHT ©️ <span class="has-text-primary">POOLTECHNIK S.R.O. 2022</span>, WEBDESIGN BY VESPOTOK
      </p>
    </div>
  </footer>
</template>

<script>
  export default {
    name: 'NavigationFooter',
    props: {
      type: String
    },
    data() {
      return {
        links: [{
            title: "O nákupu",
            children: [{
                title: "Koronavirus",
                url: "/"
              },
              {
                title: "Způsob platby",
                url: "/"
              },
              {
                title: "Dodání",
                url: "/"
              },
              {
                title: "Obchodní podmínky",
                url: "/"
              },
              {
                title: "Reklamační řád",
                url: "/"
              },
            ]
          },
          {
            title: "O nás",
            children: [{
                title: "O POOLTECHNIKu",
                url: "/"
              },
              {
                title: "Firemní stránky",
                url: "/"
              },
              {
                title: "Kontakty",
                url: "/"
              },
              {
                title: "Provozní doba",
                url: "/"
              },
              {
                title: "Spolupracujeme",
                url: "/"
              },
            ]
          },
          {
            title: "Můj účet",
            children: [{
                title: "Přihlásit se",
                url: "/"
              },
              {
                title: "Zapomenuté heslo",
                url: "/"
              },
              {
                title: "Registrace velkoobchodního partnera",
                url: "/"
              },
            ]
          },
          {
            title: "Poradna",
            children: [{
                title: "Stavba kruhového bazénu",
                url: "/"
              },
              {
                title: "Stavba nadzemního bazénu",
                url: "/"
              },
              {
                title: "Stavba ováleného bazénu",
                url: "/"
              },
              {
                title: "Problémy s bazénovou vodou",
                url: "/"
              },
            ]
          },
        ]
      }
    }
  }
</script>
<style scoped>
  .clickable {
    cursor: pointer;
  }

  .clickable:hover {
    text-decoration: underline;
  }

  .BzObFtLvl2_Text {
    font-size: var(--UICompletor);
    color: #999999;
  }

  .BzObFtLvl2_Foot {
    font-size: var(--UIMinor);
    font-weight: 900;
    margin-top: 5px;
    color: #999999;
  }

  .vdiv {
    height: calc(100% - 5px);
    width: 1px;
    margin-top: 5px;
    background-color: #d3d3d3;
  }

  .hdiv {
    width: calc(100% - 5px);
    height: 1px;
    background-color: #d3d3d3;
  }

  .BzObFtLvl_Title {
    font-size: var(--UIMinor);
    font-weight: bold;
    color: var(--PooltechnikBlue);
    margin-bottom: 10px;
  }

  .BzObFtBn_SaleTagFiller {
    width: 100px;
  }

  .BzObFt_Lvl2 {
    margin-top: 15px;
    text-align: center;
  }

  .BzObFtBn_SaleTag {
    height: 200px;
    position: absolute;
    bottom: 0;
    left: -50px;
  }

  .Spacer {
    height: 10px;
  }

  .BzObFt_Lvl1 {
    padding-top: calc(200px / 2);
    max-width: 1412px;
    margin: auto;
  }

  .BzObFt_Lvl1.bannerless {
    padding-top: 20px;

  }

  .BzObFtBn_Top {
    color: white;
    font-size: var(--HeaderMinor);
    font-weight: bold;
    margin-bottom: -15px;
    text-shadow: 0px 0px 8px rgba(150, 150, 150, 0.77);
  }

  .BzObFtBn_Bottom {
    color: white;
    font-size: 25px;
    text-shadow: 0px 0px 8px rgba(150, 150, 150, 0.77);
  }

  .fh {
    height: 100%;
    margin: 0px;
  }

  footer {
    background-color: #EBEBEB;
    min-height: 400px;
    width: 100%;
    position: relative;
  }

  .BzObFt_Banner {
    background-image: url("../../assets/footer.jpg");
    height: 150px;
    margin: auto;
    padding: 10px;
    width: 1412px;
    top: calc(-150px / 2);
    border-top-left-radius: 40px;
    border-bottom-right-radius: 40px;
    left: 0;
    right: 0;
    z-index: 8999;
    position: absolute;
  }

  @media screen and (max-width: 768px) {
    .BzObFt_Banner {
    display:none;
  }
  }
</style>