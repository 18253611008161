<template>
  <router-link tag="div" to="/login" class="BzOb_Account">
  <transition name="zoomr_f" mode="out-in">
       <img src="../../assets/account_base.svg" v-if="logged == false"/>
    <avatar username="Alena Vlasatá" v-if="logged == true" :size="48"></avatar>
    </transition>
   
  </router-link>
</template>

<script>
import Avatar from 'vue-avatar'
export default {
  name: 'NavigationAccount',
  components: {
    Avatar
  },
  props: {
    logged: Boolean
  }
}
</script>

<style scoped>
img {
  height: 48px;
  pointer-events: none;
}
.BzOb_Account {
  cursor: pointer;
}
@media screen and (max-width:768px) {
  img {
  height: 36px;
  pointer-events: none;
}
}
</style>
