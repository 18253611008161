import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Buefy from 'buefy'
import VueProgressBar from 'vue-progressbar'
import './assets/buefy.scss'
import '@mdi/font/css/materialdesignicons.css'
import router from './router'
import CookieConsent from 'vue-cookie-consent-modal';
import '../node_modules/vue-cookieconsent/vendor/cookieconsent.css'
import axios from 'axios'
Vue.use(Buefy, {
  customIconPacks: {
    'mdi': {
      sizes: {
        'default': 'mdi-20px',
        'is-small': 'mdi-20px',
        'is-medium': 'mdi-20px',
        'is-large': 'mdi-24px'
      }
    }
  }
})
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
Vue.filter('stripHTML', function (value) {
  const div = document.createElement('div')
  div.innerHTML = value
  const text = div.textContent || div.innerText || ''
  return text
});
Vue.component('cookie-consent', CookieConsent);
Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(VueProgressBar, {
  color: '#D46004',
  failedColor: 'red',
  height: '2px'
})
new Vue({
  router,
  render: h => h(App),
  watch: {
    $route(to) {
      var url = "http://www.bazeny-obchod.cz/apieshop/urls" + to.path;
      this.getDynamicRoutes(url, to.path);
    }
  },
  created() {
    var url = "http://www.bazeny-obchod.cz/apieshop/urls" + this.$router.currentRoute.path;
      this.getDynamicRoutes(url, this.$router.currentRoute.path);
  },
  methods: {
    getDynamicRoutes(url, path_local) {
      var self = this;
      axios
        .get(url)
        .then(function (response) {
          let route = response.data;
          var component = "./views/CategoryView.vue";
          var title = "Titulek";
          var profile = "pageful";
          switch (route.typ) {
            case "kategorie":
              component = "./views/CategoryView.vue";
              title = "Načítám jméno kategorie...";
              break;
            case "zbozi":
              component = "./views/ProductView.vue";
              title = "Načítám jméno produktu...";
              break;
            case "specialni":
              switch(route.uid) {
                case "kosik":
                  component = "./views/CartView.vue";
                  title = "Košík";
                  profile = "main";
                break;
                case "login":
                  component = "./views/LoginPage.vue";
                  title = "Přihlášení";
                  profile = "login";
                break;
                case "heslo":
                  component = "./views/PasswordPage.vue";
                  title = "Zapomenuté heslo";
                  profile = "postlogin";
                break;
              }
              break;
            case "texty":
              component = "./views/TextsPage.vue";
              title = "Načítám nadpis stránky...";
              break;
          }

          let newRoute = {
            path: `${path_local}`,
            component: () => import(`${component}`),
            props: {
              uid: route.uid
            },
            meta: { 
              title: title,
              profile: profile
            }
          }
          self.$router.addRoutes([newRoute])
        })

        .catch(err => console.log(err))
    },



  },
}).$mount('#app')