import Vue from 'vue'
import VueRouter from 'vue-router'
import MainPage from '@/views/MainPage.vue'
import WelcomePage from '@/views/WelcomePage.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Domov',
    component: MainPage,
    meta: { 
      title: 'Domov',
      profile: 'main'
    }
  },
  {
    path: '/vitejte',
    name: 'Vítejte',
    component: WelcomePage,
    meta: { 
      title: 'Vítejte',
      profile: 'postlogin'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
const DEFAULT_TITLE = 'BazényObchod.cz';
router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title + " | " + DEFAULT_TITLE || DEFAULT_TITLE;
    });


});




/*class Counter {
  constructor(value) {
    this.technical = value
  }
}*/


export default router
