<template>
  <div class="BzOb_Search" :class="theme">

    <div class="BzObSr_Box" :class="{active: active}">
      <b-input placeholder="Hledejte zde..." type="search" :custom-class="get_class"
        class="SearchField is-hidden-tablet-only" v-model="searched" icon-right="magnify" @focus="activate"
        @blur="deactivate">
      </b-input>
      <div class="BzObSrBx_SearchBox" :class="{ending:ending}" v-if="searched != ''">
        <div class="BzObSrBxSb_Info">
          Nalezeno <b>{{results.counts.all}}</b> výsledků ({{results.time}}ms)
        </div>
        <b-tabs v-model="activeTab" class="tabssearch">
          <b-tab-item>
            <template #header>
              <span> <b>Vše</b> <span class="bl">({{results.counts.all}})</span></span>
            </template>
            <div class="BzObSrBx_NothingFound" v-if="results.counts.all == 0">
              <img src="../../assets/dolphindoesnotknow.svg" class="BzObSrBxNf_Image" />
              <div class="BzObSrBxNf_Text">
                <p class="BzObSrBxNfTx_1">Ale ne...</p>
                <p class="BzObSrBxNfTx_2">{{searched}} <b>jsme nenašli...</b></p>
              </div>
            </div>
            <div class="BzObSrBx_SomethingFound All" v-if="results.counts.all != 0">
              <div class="BzObSrBxSf_Left">
                <ul>
                  <router-link tag="li" :to="item.url" v-for="item in results.items.products" :key="item.url"
                    class="BzObSrBxSfLf_Item">
                    <div class="columns is-variable is-1 is-vcentered" @click="deactivate_really">
                      <div class="column is-narrow">
                        <div class="BzObSrBxSfLf_Img">
                          <img :src="item.img" />
                        </div>
                      </div>
                      <div class="column">
                        <div class="BzObSrBxSfLf_Text">
                          <p class="BzObSrBxSfLfTx_1">{{item.name}} </p>
                          <p class="BzObSrBxSfLfTx_2" v-for="param in item.params" :key="param.value">
                            <span class="has-text-grey-light">{{param.name}}: </span>
                            <span class="has-text-weight-bold">{{param.value}}</span>
                          </p>

                        </div>
                      </div>
                      <div class="column is-narrow">
                        <img src="../../assets/eye3.svg" class="BzObSrBxSfLf_Eye" />
                      </div>
                      <div class="column is-narrow">
                        <p class="BzObSrBxSfLf_Price">
                          {{item.price}}
                        </p>
                      </div>
                    </div>
                  </router-link>
                </ul>
              </div>
              <div class="BzObSrBxSf_Right">
                <ul>
                  <router-link tag="li" :to="item.name" v-for="item in results.items.manufacturers" :key="item.name"
                    class="BzObSrBxSfRg_Item">
                    <div class="columns is-variable is-1 is-vcentered" @click="deactivate_really">
                      <div class="column is-narrow">
                        <div class="BzObSrBxSfRg_Img">
                          <img src="../../assets/placeholder_manufacturer.png" />
                        </div>
                      </div>
                      <div class="column">
                        <div class="BzObSrBxSfRg_Text">
                          <p class="BzObSrBxSfRgTx_1">{{item.name}} </p>
                          <p class="BzObSrBxSfRgTx_2">
                            <span>
                              <img src="../../assets/eye4.svg" class="BzObSrBxSfRg_Eye" />
                            </span>
                            <span class="BzObSrBxSfRg_Tx">
                              Zobrazit produkty výrobce
                            </span>
                          </p>

                        </div>
                      </div>

                    </div>
                  </router-link>
                </ul>
                <div data-v-0df2b1ec="" class="BzObPcCd_FilterBorder"></div>
                <ul>
                  <router-link tag="li" :to="item.name" v-for="item in results.items.categories" :key="item.name"
                    class="BzObSrBxSfRg_Item">
                    <div class="columns is-variable is-1 is-vcentered" @click="deactivate_really">
                      <div class="column is-narrow">
                        <div class="BzObSrBxSfRg_Img Darken">
                          <img src="../../assets/placeholder_category.png" />
                        </div>
                      </div>
                      <div class="column">
                        <div class="BzObSrBxSfRg_Text">
                          <p class="BzObSrBxSfRgTx_1">{{item.name}} <span>
                              <img src="../../assets/eye4.svg" class="BzObSrBxSfRg_Eye" />
                            </span></p>


                        </div>
                      </div>

                    </div>
                  </router-link>
                </ul>
              </div>
            </div>
          </b-tab-item>
          <b-tab-item>
            <template #header>
              <span> <b>Produkty</b> <span class="bl">({{results.counts.products}})</span></span>
            </template>
            <div class="BzObSrBx_NothingFound" v-if="results.counts.products == 0">
              <img src="../../assets/dolphindoesnotknow.svg" class="BzObSrBxNf_Image" />
              <div class="BzObSrBxNf_Text">
                <p class="BzObSrBxNfTx_1">Ale ne...</p>
                <p class="BzObSrBxNfTx_2">Produkt „{{searched}}“ <b>jsme nenašli...</b></p>
              </div>
            </div>
            <div class="BzObSrBx_SomethingFound has-text-left BzObSrBx_ProductsOnly"
              v-if="results.counts.products != 0">
              <ul>
                <router-link tag="li" :to="item.url" v-for="item in results.items.products" :key="item.url"
                  class="BzObSrBxSfLf_Item">
                  <div class="columns is-variable is-1 is-vcentered" @click="deactivate_really">
                    <div class="column is-narrow">
                      <div class="BzObSrBxSfLf_Img">
                        <img :src="item.img" />
                      </div>
                    </div>
                    <div class="column is-narrow">
                      <div class="BzObSrBxSfLf_Text">
                        <p class="BzObSrBxSfLfTx_1">{{item.name}} </p>
                        <p class="BzObSrBxSfLfTx_2" v-for="param in item.params" :key="param.value">
                          <span class="has-text-grey-light">{{param.name}}: </span>
                          <span class="has-text-weight-bold">{{param.value}}</span>
                        </p>

                      </div>
                    </div>
                    <div class="column">
                      <div class="BzObSrBxSfLf_Div"></div>
                    </div>
                    <div class="column is-narrow">
                      <img src="../../assets/eye3.svg" class="BzObSrBxSfLf_Eye" />
                    </div>
                    <div class="column is-narrow">
                      <p class="BzObSrBxSfLf_Price">
                        {{item.price}}
                      </p>
                    </div>
                  </div>
                </router-link>
              </ul>
            </div>
          </b-tab-item>
          <b-tab-item>
            <template #header>
              <span> <b>Kategorie</b> <span class="bl">({{results.counts.categories}})</span></span>
            </template>
            <div class="BzObSrBx_NothingFound" v-if="results.counts.categories == 0">
              <img src="../../assets/dolphindoesnotknow.svg" class="BzObSrBxNf_Image" />
              <div class="BzObSrBxNf_Text">
                <p class="BzObSrBxNfTx_1">Ale ne...</p>
                <p class="BzObSrBxNfTx_2">Kategorii „{{searched}}“ <b>jsme nenašli...</b></p>
              </div>
            </div>
            <div class="BzObSrBx_SomethingFound" v-if="results.counts.categories != 0">
              <ul>
                <router-link tag="li" :to="item.name" v-for="item in results.items.categories" :key="item.name"
                  class="BzObSrBxSfRg_Item">
                  <div class="columns is-variable is-1 is-vcentered" @click="deactivate_really">
                    <div class="column is-narrow">
                      <div class="BzObSrBxSfRg_Img Darken">
                        <img src="../../assets/placeholder_category.png" />
                      </div>
                    </div>
                    <div class="column">
                      <div class="BzObSrBxSfRg_Text">
                        <p class="BzObSrBxSfRgTx_1">{{item.name}} <span>
                            <img src="../../assets/eye4.svg" class="BzObSrBxSfRg_Eye" />
                          </span></p>


                      </div>
                    </div>

                  </div>
                </router-link>
              </ul>
            </div>
          </b-tab-item>
          <b-tab-item>
            <template #header>
              <span> <b>Výrobci</b> <span class="bl">({{results.counts.manufacturers}})</span></span>
            </template>
            <div class="BzObSrBx_NothingFound" v-if="results.counts.manufacturers == 0">
              <img src="../../assets/dolphindoesnotknow.svg" class="BzObSrBxNf_Image" />
              <div class="BzObSrBxNf_Text">
                <p class="BzObSrBxNfTx_1">Ale ne...</p>
                <p class="BzObSrBxNfTx_2">Výrobce „{{searched}}“ <b>jsme nenašli...</b></p>
              </div>
            </div>
            <div class="BzObSrBx_SomethingFound" v-if="results.counts.manufacturers != 0">
              <ul>
                <router-link tag="li" :to="item.name" v-for="item in results.items.manufacturers" :key="item.name"
                  class="BzObSrBxSfRg_Item">
                  <div class="columns is-variable is-1 is-vcentered" @click="deactivate_really">
                    <div class="column is-narrow">
                      <div class="BzObSrBxSfRg_Img">
                        <img src="../../assets/placeholder_manufacturer.png" />
                      </div>
                    </div>
                    <div class="column">
                      <div class="BzObSrBxSfRg_Text">
                        <p class="BzObSrBxSfRgTx_1">{{item.name}} </p>
                        <p class="BzObSrBxSfRgTx_2">
                          <span>
                            <img src="../../assets/eye4.svg" class="BzObSrBxSfRg_Eye" />
                          </span>
                          <span class="BzObSrBxSfRg_Tx">
                            Zobrazit produkty výrobce
                          </span>
                        </p>

                      </div>
                    </div>

                  </div>
                </router-link>
              </ul>

            </div>
          </b-tab-item>
          <b-tab-item>
            <template #header>
              <span> <b>Soubory</b> <span class="bl">({{results.counts.files}})</span></span>
            </template>
            <div class="BzObSrBx_NothingFound" v-if="results.counts.files == 0">
              <img src="../../assets/dolphindoesnotknow.svg" class="BzObSrBxNf_Image" />
              <div class="BzObSrBxNf_Text">
                <p class="BzObSrBxNfTx_1">Ale ne...</p>
                <p class="BzObSrBxNfTx_2">Soubor „{{searched}}“ <b>jsme nenašli...</b></p>
              </div>
            </div>
            <div class="BzObSrBx_SomethingFound" v-if="results.counts.files != 0">

            </div>
          </b-tab-item>

        </b-tabs>
      </div>
    </div>
    <img src="../../assets/search_base.svg" class="noselect is-hidden-desktop  is-hidden-mobile" v-if="theme == 'base'" />
    <img src="../../assets/search_white.svg" class="noselect is-hidden-desktop  is-hidden-mobile" v-if="theme == 'white'" />
  </div>
</template>

<script>
  export default {
    name: 'NavigationSearch',
    props: {
      theme: String,
      activated: Boolean
    },
    data() {
      return {
        active: false,
        ending: false,
        searched: "",
        activeTab: 0,
        results: {
          time: 0.35,
          counts: {
            all: 5,
            products: 2,
            categories: 3,
            manufacturers: 1,
            files: 0
          },
          items: {
            categories: [{
              name: "Bazény Marimex",
              logo: "../../../assets/placeholder_manufacturer.png"
            }, {
              name: "Bazény Marimex",
              logo: "../../../assets/placeholder_manufacturer.png"
            }, {
              name: "Bazény Marimex",
              logo: "../../../assets/placeholder_manufacturer.png"
            }],
            manufacturers: [{
              name: "Bazény A+M Borovička",
              logo: "../../../assets/placeholder_manufacturer.png"
            }],
            products: [{
                img: "https://www.bazeny-obchod.cz/kramky/zbozi/velke/k320130125_084606.jpg",
                name: "Bazén Toscano",
                gift: true,
                params: [{
                    name: "Velikost",
                    value: "3,2 x 5,25 x 1,5 M"
                  },
                  {
                    name: "Dárek",
                    value: "1 kg Multifunkčních tablet 5v1"
                  }
                ],
                price: "39 585 Kč",
                url: "relax-4-16-x-1-2-m.html"
              },
              {
                img: "https://www.bazeny-obchod.cz/kramky/zbozi/velke/k320130125_084606.jpg",
                name: "Bazén Toscano",
                gift: true,
                params: [{
                    name: "Velikost",
                    value: "3,2 x 5,25 x 1,5 M"
                  },
                  {
                    name: "Dárek",
                    value: "1 kg Multifunkčních tablet 5v1"
                  }
                ],
                price: "39 585 Kč",
                url: "relax-4-16-x-1-2-m.html"
              },
              {
                img: "https://www.bazeny-obchod.cz/kramky/zbozi/velke/k320130125_084606.jpg",
                name: "Bazén Toscano",
                gift: true,
                params: [{
                    name: "Velikost",
                    value: "3,2 x 5,25 x 1,5 M"
                  },
                  {
                    name: "Dárek",
                    value: "1 kg Multifunkčních tablet 5v1"
                  }
                ],
                price: "39 585 Kč",
                url: "relax-4-16-x-1-2-m.html"
              },
              {
                img: "https://www.bazeny-obchod.cz/kramky/zbozi/velke/k320130125_084606.jpg",
                name: "Bazén Toscano",
                gift: true,
                params: [{
                    name: "Velikost",
                    value: "3,2 x 5,25 x 1,5 M"
                  },
                  {
                    name: "Dárek",
                    value: "1 kg Multifunkčních tablet 5v1"
                  }
                ],
                price: "39 585 Kč",
                url: "relax-4-16-x-1-2-m.html"
              }
            ]
          }
        }
      }
    },
    computed: {
      get_class: function () {
        if (this.active) {
          return "borderless";
        }
        return "";
      }
    },
    methods: {
      activate: function () {
        this.ending = false;
        this.$emit('activated', true);
        this.active = true;

      },
      deactivate: function () {
        if (this.searched == "") {
          this.ending = true;
          setTimeout(() => {
            this.$emit('deactivated', true);
            this.active = false;
          }, 600);

        }
      },
      deactivate_really: function () {
        this.ending = true;
        setTimeout(() => {
          this.$emit('deactivated', true);
          this.active = false;

          this.searched = "";
        }, 600);


      }
    }
  }
</script>

<style>
  .borderless {
    transition: all 0.5s ease;
    border: none !important;
    box-shadow: none !important;
  }

  .tabssearch>.tabs ul {
    padding-left: 25px;
  }

  .tabssearch>.tabs li.is-active a {
    color: black !important;
  }

  .white .input
  {
    background: none;
  }
  .white .input::placeholder
  {
    color: white;
  }
  .white .active .input
  {
    background: white;
  }
  .white .active .input::placeholder
  {
    color: var(--PooltechnikBlue);
  }
</style>

<style scoped>
  .BzObSrBxSfRg_Img.Darken {
    mix-blend-mode: darken;
  }

  .BzObPcCd_FilterBorder {
    height: 10px;
    width: 100%;
    background-image: url("../../assets/border.svg");
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .BzObSrBxSfRg_Eye {
    height: 1rem;
    margin-bottom: -2px;
  }

  .BzObSrBx_ProductsOnly {
    padding: 9px
  }

  .BzObSrBxSfLf_Div {
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
    height: 1px;
    background-color: lightgray;
    transition: all 0.5s ease;
  }

  .BzObSrBxSfLf_Item {
    margin-bottom: 15px;
    transition: all 0.5s ease;
    cursor: pointer;
  }

  .BzObSrBxSfLf_Item:hover .BzObSrBxSfLf_Div {
    background-color: var(--PooltechnikOrange);
  }

  .BzObSrBxSfLf_Item:last-child {
    margin-bottom: 0px;
  }

  .BzObSrBxSfLf_Eye {
    height: 30px;

    margin-bottom: -6px;
    cursor: pointer;
  }

  .BzObSrBxSfRg_Img {
    height: 77px;
    width: 77px;
    position: relative;
    margin-right: 7px;
    overflow: hidden;
  }

  .BzObSrBxSfRg_Img img {
    height: auto;
    margin: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;

  }

  .BzObSrBxSfRg_Tx {
    color: var(--PooltechnikOrange);
  }

  .BzObSrBxSfLfTx_1 {
    color: var(--PooltechnikBlue);
    font-size: var(--UIMinor);
    transition: all 0.5s ease;
    font-weight: bold;
  }

  .BzObSrBxSfRgTx_1 {
    color: var(--PooltechnikBlue);
    font-size: var(--UIMinor);
    transition: all 0.5s ease;
    font-weight: bold;
  }

  .BzObSrBxSfLf_Item:hover .BzObSrBxSfLfTx_1,
  .BzObSrBxSfRg_Item:hover .BzObSrBxSfRgTx_1 {
    color: var(--PooltechnikOrange);
  }

  .BzObSrBxSfLf_Img {
    height: 77px;
    width: 77px;
    border-radius: 10px;
    transition: all 0.2s ease;
    margin-right: 10px;
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 13px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 13px -3px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 13px -3px rgba(0, 0, 0, 0.75);
  }

  .BzObSrBxSfLf_Img img {
    height: 100%;
    width: 100%;
  }

  .BzObSrBxSfLf_Item:hover .BzObSrBxSfLf_Img {
    border-radius: 5px;
  }

  .tabssearch {
    height: 100%;
  }

  .BzObSrBxSb_Info {
    position: absolute;
    width: calc(100% - 40px);
    margin: 20px;
    margin-top: 7px;
    text-align: right;
    pointer-events: none;
    color: grey;
  }

  .BzObSrBx_NothingFound {
    display: flex;
    justify-content: center;
    text-align: left;
    align-items: flex-end;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .bl {
    color: var(--PooltechnikBlue)
  }

  .BzObSrBxNfTx_1 {
    font-size: var(--HeaderMinor);
    color: var(--PooltechnikBlue);
  }

  .BzObSrBxNfTx_2 {
    font-size: var(--UIMajor);
    margin-top: -15px;
  }

  .BzObSrBxSfLf_Price {
    color: var(--PooltechnikOrange);
    font-size: var(--UIMinor);
    font-weight: bold;
  }

  .BzOb_Search {
    margin: auto;
  }

  img {
    height: 48px;
    pointer-events: none;
  }

  .input,
  b-input {
    font-size: var(--UIMinor) !important;
    height: 45px !important;
  }

  .BzObSrBxNf_Image {
    width: 250px;
    height: auto;

    margin-right: -50px;
  }

  .BzObSr_Box {
    height: 45px;
    width: 350px;
    margin: auto;
    position: relative;
    transition: width 0.5s ease-in-out;
  }

  .BzObSrBx_SomethingFound.All {
    display: flex;
    margin: -1rem;
    min-height: 400px;
    text-align: left;
  }

  .SearchField {
    position: absolute;
    z-index: 500000;
    width: 100%;
  }

  .BzObSr_Box.active {
    width: 100%;
    transition: width 0.5s ease-in-out;
    -webkit-box-shadow: 0px 0px 82px -37px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 82px -37px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 82px -37px rgba(0, 0, 0, 0.75);
  }

  .BzObSrBxSf_Right {
    background-color: #f3f3f3de;
    border-bottom-right-radius: 20px;
    width: 40%;
    min-height: 400px;
    padding: 25px
  }

  .BzObSrBxSf_Left {
    width: 60%;
    height: 100%;
    padding: 25px
  }

  .BzObSrBx_SearchBox {
    padding-top: 20px;
    width: 100%;
    -webkit-box-shadow: 0px 0px 100px -37px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 100px -37px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 100px -37px rgba(0, 0, 0, 0.5);
    min-height: 400px;
    max-height: 90vh;
    margin-top: 20px;
    z-index: 90000;
    position: absolute;
    background: white;
    -webkit-animation: scale-in-ver-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-ver-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation-delay: 400ms;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
  }

  .BzObSrBx_SearchBox.ending {
    -webkit-animation: scale-out-ver-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: scale-out-ver-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-11-13 19:17:36
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation scale-out-ver-top
 * ----------------------------------------
 */
  @-webkit-keyframes scale-out-ver-top {
    0% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }

    100% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }
  }

  @keyframes scale-out-ver-top {
    0% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }

    100% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-11-13 19:11:49
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation scale-in-ver-top
 * ----------------------------------------
 */
  @-webkit-keyframes scale-in-ver-top {
    0% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }

    100% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }
  }

  @keyframes scale-in-ver-top {
    0% {
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }

    100% {
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
      transform-origin: 100% 0%;
      opacity: 1;
    }
  }
</style>