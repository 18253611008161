<template>
  <div id="app" :class="get_back">
    <vue-progress-bar></vue-progress-bar>
    <NavigationBar phone_num="777 752 184" :theme="get_theme" @menu_opening="$refs.menuChild.openMenu()"
      :closeMenu="menu_closing" :path="path" :logged="logged" :favorites="favorites.items.length"
      :comparison="comparison.items.length" :type="get_type" :cart="cart" />
    <NavigationBarMobile :theme="get_theme" :window_width="windowWidth" @menu_opening="$refs.menuChild.openMenu()"
      :closeMenu="menu_closing" :type="get_type" :cart="cart" v-if="windowWidth <= 768" />
    <div class="SecondaryBackground" :class="get_back_s"></div>
    <MainMenu ref="menuChild" @closingMenu="menu_closing"></MainMenu>
    <transition :name="get_animation" mode="out-in">
      <router-view />
    </transition>
    <!--<cookie-consent dialog-cookie-name="cookie-consent-cookie-dialog-box" cookie-page-url="https://example.com" :cookies="[
        {
          displayName: 'Technical',
          cookieName: 'technical',
          description: 'Technical cookies...',
          optional: false,
          initialStatus: true,
        }
    ]"></cookie-consent>-->
  </div>
</template>

<script>
  import NavigationBar from './components/navbar_components/NavigationBar.vue'
  import NavigationBarMobile from './components/navbar_mobile/NavigationBarMobile.vue'
  import MainMenu from './components/navigation/MainMenu.vue'
  import axios from 'axios'
  export default {
    name: 'BazenyObchod.cz',
    components: {
      NavigationBar,
      NavigationBarMobile,
      MainMenu
    },
    props: {
      openMenu1: {
        type: Function
      },
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
    },

    created() {
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      })
      this.profile = this.$route.meta.profile;
      this.$Progress.start();
      this.cart_update();
      if (localStorage.favorites) {
        this.favorites = JSON.parse(localStorage.favorites);
      }
      if (localStorage.comparison) {
        this.comparison = JSON.parse(localStorage.comparison);
      }
      this.$router.beforeEach((to, from, next) => {
        this.profile = to.meta.profile;
        window.scrollTo(0, 0);
        if (to.meta.progress !== undefined) {
          let meta = to.meta.progress;
          this.$Progress.parseMeta(meta);

        }
        this.$Progress.start();
        this.expanded = false;
        next();
      });
    },
    data() {
      return {
        profile: "main",
        menu_open: false,
        path: null,
        logged: false,
        windowWidth: window.innerWidth,
        cart: {
          /** Jelikož jde localStorage vidět, tak trochu vyděsíme hackery */
          legal_warning: "By observing local storage and possibly studying the innerworkings of BazényObchod.cz ecommerce web presentation, you are breaking the license agreement between POOLTECHNIK s.r.o. and you. This incident may be reported to the Police of Czech Republic and a legal action may be taken between you and POOLTECHNIK s.r.o.",
          items: []
        },
        favorites: {
          /** Jelikož jde localStorage vidět, tak trochu vyděsíme hackery */
          legal_warning: "By observing local storage and possibly studying the innerworkings of BazényObchod.cz ecommerce web presentation, you are breaking the license agreement between POOLTECHNIK s.r.o. and you. This incident may be reported to the Police of Czech Republic and a legal action may be taken between you and POOLTECHNIK s.r.o.",
          items: []
        },
        comparison: {
          /** Jelikož jde localStorage vidět, tak trochu vyděsíme hackery */
          legal_warning: "By observing local storage and possibly studying the innerworkings of BazényObchod.cz ecommerce web presentation, you are breaking the license agreement between POOLTECHNIK s.r.o. and you. This incident may be reported to the Police of Czech Republic and a legal action may be taken between you and POOLTECHNIK s.r.o.",
          items: []
        }
      }
    },
    methods: {
      /**
       * Navigation methods
       */
      menu_closing: function () {
        this.menu_open = false;
      },
      menu_opening: function () {
        this.openMenu1();

      },
      onResize() {
        this.windowWidth = window.innerWidth
      },
      /**
       * Cart manipulation methods
       */
      add_to_cart: function (product, amount = 1/*, origin = "card"*/) {
        /*
        Local cart fn
        this.cart.items.push({
          cart_item_id: this.uniqid("BZOB:", true),
          cart_item_amount: amount,
          cart_item_array: product,
          cart_item_origin: origin
        });*/
        var self = this;
        axios.get("https://www.bazeny-obchod.cz/apieshop/kosik-pridej/" + product.id, {
          pocet: amount
        }, { withCredentials: true }).then((response) => {
          if (response.data.ok != null) {
            self.$buefy.snackbar.open({
              message: 'Produkt ' + product.nazev + ' byl přidán do košíku.',
              type: 'is-primary',
              position: 'is-bottom',
              actionText: 'Do košíku',
              indefinite: false,
              onAction: () => {
                this.$buefy.toast.open({
                  message: 'Action pressed',
                  queue: false
                })
              }
            });
            self.cart_update();
          }
          else
          {
            this.$buefy.snackbar.open({
              message: 'Přidání do košíku se nepovedlo.',
              type: 'is-danger',
              indefinite: false
            });
          }
        });

      },
      remove_from_cart: function (item_id) {
        this.remove_from_array(item_id);
        this.cart_update();
      },
      change_cart_amount: function (item_id, amount) {
        var arr = this.cart.items;
        for (var i = 0; i < arr.length; i++) {
          if (arr[i].cart_item_id == item_id) {
            switch (amount) {
              case "+":
                arr[i].cart_item_amount += 1;
                break;
              case "-":
                if (arr[i].cart_item_amount > 1) {
                  arr[i].cart_item_amount -= 1;
                }
                break;
              default:
                arr[i].cart_item_amount = amount;
                break;
            }

          }
        }

        this.cart_update();
      },
      cart_update: function () {
        var self = this;
        axios.post("https://www.bazeny-obchod.cz/apieshop/kosik-produkty/", {}, { withCredentials: true }).then((response)=>{
          if(response.data.produkty != null) {
            self.cart.items = response.data.produkty
          }
        });
      },
      /**
       * Favorites
       */
      add_to_favorites: function (product, origin = "card", link = product.seo_url) {
        this.favorites.items.push({
          favorite_item_id: this.uniqid("BZOB:", true),
          favorite_item_array: product,
          favorite_item_link: link,
          favorite_item_origin: origin
        });
        this.$buefy.snackbar.open({
          message: 'Produkt ' + product.nazev + ' byl přidán do oblíbených.',
          type: 'is-primary',
          position: 'is-bottom',
          actionText: 'Oblíbené',
          indefinite: false,
          onAction: () => {
            this.$buefy.toast.open({
              message: 'Action pressed',
              queue: false
            })
          }
        });
        this.favorites_update();
      },
      favorites_update: function () {
        localStorage.favorites = JSON.stringify(this.favorites);
      },
      /**
       * Comparing
       */
      add_to_comparison: function (product, origin = "card") {
        this.comparison.items.push({
          comparison_item_id: this.uniqid("BZOB:", true),
          comparison_item_array: product,
          comparison_item_origin: origin
        });
        this.$buefy.snackbar.open({
          message: 'Produkt ' + product.nazev + ' byl přidán do porovnání.',
          type: 'is-primary',
          position: 'is-bottom',
          actionText: 'Porovnání',
          indefinite: false,
          onAction: () => {
            this.$buefy.toast.open({
              message: 'Action pressed',
              queue: false
            })
          }
        });
        this.comparison_update();
      },
      comparison_update: function () {
        localStorage.comparison = JSON.stringify(this.comparison);
      },
      /**
       * Helper methods
       */
      uniqid: function (prefix = "", random = false) {
        const sec = Date.now() * 1000 + Math.random() * 1000;
        const id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
        return `${prefix}${id}${random ? `.${Math.trunc(Math.random() * 100000000)}`:""}`;
      },
      remove_from_array: function (cart_item_id) {
        var i = 0;
        while (i < this.cart.items.length) {
          if (this.cart.items[i].cart_item_id === cart_item_id) {
            this.cart.items.splice(i, 1);
          } else {
            ++i;
          }
        }
      }
    },
    computed: {
      items_size() {
        var total = 0;
        var arr = this.cart.items;
        for (var i = 0; i < arr.length; i++) {
          total += arr[i].cart_item_amount
        }

        return total;
      },
      get_animation() {
        switch (this.profile) {
          case "main":
            return "zoom";

          case "fullpic":
            return "fadde";
          case "login":
            return "zoom";
          case "postlogin":
            return "zoomr";
          case "pageful":
            return "fade";

          default:
            return "fade";
        }
      },
      /* beforeCreate() {
         this.$cc.on('consent-changed', () => {
           console.log('cookie consent changed, new user preferences:',
             this.$cc.getUserPreferences())

         })
         return true;
       },*/
      get_type() {
        switch (this.profile) {
          case "main":
            return "bare";

          case "fullpic":
            return "path";
          case "login":
            return "bare";
          case "postlogin":
            return "bare";
          case "pageful":
            return "path";

          default:
            return "bare";
        }
      },
      get_theme() {
        switch (this.profile) {
          case "main":
            return "base";

          case "fullpic":
            return "white";
          case "login":
            return "white";
          case "postlogin":
            return "white";
          case "pageful":
            return "base";

          default:
            return "base";
        }
      },
      get_back() {
        switch (this.profile) {
          case "main":
            return "BzOb_Color";

          case "fullpic":
            return "BzOb_Dark";

          case "login":
            return "BzOb_Dark";

          case "postlogin":
            return "BzOb_Dark";
          case "pageful":
            return "BzOb_White";

          default:
            return "BzOb_Color";
        }
      },
      get_back_s() {
        switch (this.profile) {
          case "main":
            return "";

          case "fullpic":
            return "";

          case "login":
            return "BzOb_Login";

          case "postlogin":
            return "BzOb_Login";
          case "pageful":
            return "";

          default:
            return "";
        }
      },
      get_sp_pad() {
        return "Sp_Pad " + this.profile;
      }
    }
  }
</script>

<style>
  :root {
    --UICompletor: 14px;
    --UIMinor: 16px;
    --UIMajor: 18px;
    --HeaderMicro: 34px;
    --HeaderMinor: 43px;
    --HeaderMajor: 103px;
    --PooltechnikBlue: #17B3CC;
    --PooltechnikBlueLight: #D7F2F7;
    --PooltechnikOrangeLight: #ffceb7;
    --PooltechnikOrange: #F37135;
    --PooltechnikOrangeDark: #D46004;
    --PooltechnikYellow: #F2D02A;
    --JicinBlue: #10549D;
    --JicinGold: #FFD035;
  }

  @media (prefers-reduced-motion) {
    * {
      animation: none !important;
      transition: none !important;
    }
  }

  #app {
    background-color: white;
    background-size: 80%;
    background-position: top center;
    background-repeat: no-repeat;
    background-image: none;
    height: 100%;
    width: 100%;
    background-image: url("@/assets/background.svg");

    transition: background-color 1s ease;
    min-height: 100vh;
    overflow-x: hidden;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .SecondaryBackground {
    position: absolute;
    width: 100vw;
    opacity: 0;
    height: 100vh;
    background-image: url("@/assets/empty.png");
    background-position: center;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    transition: background-image, opacity 1s ease;
    pointer-events: none;
  }

  .is-menubutton {
    text-align: left !important;
    font-weight: bold;
    margin-bottom: 10px;
    justify-content: unset !important;
  }

  .BzObPc_ContentDown {
    background-color: #F6F6F6;
    min-height: 1540px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .oddelovac svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 52px;
    transform: rotateY(180deg) rotate(180deg);
  }

  .menubutton-icon-wrapper {}

  .menubutton-icon {
    height: 25px;
    width: 25px;
    display: flex;
  }

  .BzOb_Color {
    background-color: #F3F3F3 !important;
    background-size: 80%;
    background-position: top center;
    background-repeat: no-repeat;


  }

  .BzOb_Dark {
    background-color: #3f3f3f !important;
    background-size: 80%;
    background-position: top center;
    background-repeat: no-repeat;


  }

  .BzOb_Login {
    opacity: 1;

    transition: background-image 1s ease;
    background-image: url("@/assets/login.png") !important;
  }

  .main.Sp_Pad {
    padding-top: 130px;
    margin: auto;
    max-width: 1490px;

  }

  .Sp_PadSides {
    margin: auto;
    max-width: 1412px;
  }

  .pageful.Sp_Pad {
    padding-top: 190px;
    margin: auto;
    max-width: 1412px;

  }

  @media screen and (max-width: 768px) {
    .pageful.Sp_Pad {
      padding-top: 130px;
      margin: auto;
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  .BzOb_White {
    background-color: white;
    transition: background-color 1s ease;
  }

  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  ::-moz-selection {
    color: white;
    background: var(--PooltechnikBlue);
  }

  ::selection {
    color: white;
    background: var(--PooltechnikBlue);
  }

  .RW {
    height: 100%;
  }

  .input,
  .button,
  .select select,
  .textarea {
    border-radius: 0px !important;
    border-top-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    border-width: 2pt !important;
  }

  .fade-enter-active {
    -webkit-animation: fade-in-fwd 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: fade-in-fwd 0.3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }

  .fade-leave-active {
    -webkit-animation: fade-out-bck 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: fade-out-bck 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .zoom-enter-active {
    -webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .zoom-leave-active {
    -webkit-animation: slide-out-bottom 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: slide-out-bottom 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

  .zoomr-enter-active {
    -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .zoomr-leave-active {
    -webkit-animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

  .zoomr_f-enter-active {
    -webkit-animation: slide-in-bottom-f 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-bottom-f 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .zoomr_f-leave-active {
    -webkit-animation: slide-out-top-f 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: slide-out-top-f 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

  .zoom_f-enter-active {
    -webkit-animation: slide-in-top-f 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-top-f 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .zoom_f-leave-active {
    -webkit-animation: slide-out-bottom-f 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: slide-out-bottom-f 0.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  }

  h1.BzObCc_title {
    font-size: var(--HeaderMajor);
    color: black;
    font-weight: bold;
  }

  h2.BzObCc_title {
    font-size: var(--HeaderMinor);
    color: black;
    font-weight: bold;
  }

  h3.BzObCc_title {
    font-size: var(--HeaderMicro);
    color: black;
    font-weight: bold;
  }

  h4.BzObCc_title {
    font-size: var(--UIMinor);
    color: black;
    font-weight: bold;
  }

  .notices {
    z-index: 80000 !important;
  }

  .pagination-link {
    background-color: #DEDEDE;
    border-radius: 0px !important;
    border-bottom-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    font-weight: bold;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-12-29 13:3:12
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
  @-webkit-keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-100px);
      transform: translateY(-100px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-100px);
      transform: translateY(-100px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @-webkit-keyframes slide-in-top-f {
    0% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-top-f {
    0% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-12-29 13:7:1
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
  @-webkit-keyframes slide-in-bottom-f {
    0% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-bottom-f {
    0% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-12-29 13:7:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation slide-out-top
 * ----------------------------------------
 */
  @-webkit-keyframes slide-out-top-f {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
      opacity: 0;
    }
  }

  @keyframes slide-out-top-f {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
      opacity: 0;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-12-29 13:7:1
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
  @-webkit-keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-12-29 13:7:18
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation slide-out-top
 * ----------------------------------------
 */
  @-webkit-keyframes slide-out-top {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateY(-100px);
      transform: translateY(-100px);
      opacity: 0;
    }
  }

  @keyframes slide-out-top {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateY(-100px);
      transform: translateY(-100px);
      opacity: 0;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-12-29 13:2:19
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation slide-out-bottom
 * ----------------------------------------
 */
  @-webkit-keyframes slide-out-bottom {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
      opacity: 0;
    }
  }

  @keyframes slide-out-bottom {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateY(100px);
      transform: translateY(100px);
      opacity: 0;
    }
  }

  @-webkit-keyframes slide-out-bottom-f {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px);
      opacity: 0;
    }
  }

  @keyframes slide-out-bottom-f {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateY(10px);
      transform: translateY(10px);
      opacity: 0;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-12-29 13:1:15
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation scale-out-center
 * ----------------------------------------
 */
  @-webkit-keyframes scale-out-center {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 1;
    }
  }

  @keyframes scale-out-center {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 1;
    }
  }

  /* ----------------------------------------------
 * Generated by Animista on 2022-12-29 13:0:40
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
  @-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;
    }
  }

  @-webkit-keyframes fade-out-bck {
    0% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateZ(-80px);
      transform: translateZ(-80px);
      opacity: 0;
    }
  }

  @keyframes fade-out-bck {
    0% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
    }

    100% {
      -webkit-transform: translateZ(-80px);
      transform: translateZ(-80px);
      opacity: 0;
    }
  }

  @-webkit-keyframes fade-in-fwd {
    0% {
      -webkit-transform: translateZ(-80px);
      transform: translateZ(-80px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
    }
  }

  @keyframes fade-in-fwd {
    0% {
      -webkit-transform: translateZ(-80px);
      transform: translateZ(-80px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      opacity: 1;
    }
  }

  #app>div.Bz_Top>div>div.BzOb_NavigationBar>div>div.column.is-hidden-mobile.has-text-centered.is-narrow>div>div>div.control.SearchField.is-hidden-tablet-only.has-icons-right.is-clearfix>span {
    color: var(--PooltechnikBlue);
    height: 2.7em;
  }

  #app>div.Bz_Top>div>div.BzOb_NavigationBar>div>div.column.is-hidden-mobile.has-text-centered.is-narrow>div>div>div.control.SearchField.is-hidden-tablet-only.has-icons-right.is-clearfix>span>i {
    font-size: 23px !important
  }
</style>