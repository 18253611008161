<template>
  <div class="back">
    <div class="BzObMp_CarouselBody noselect" draggable="false">
      <div class="BzObMpCb_Main">
        <transition :name="animation" mode="out-in">
          <div class="BzObMpCbMn_Image" :key="current"
            :style="{ backgroundImage: 'url('+carousel[current].image + ')' }">
            <div class="BzObMp_TagParent" @click="animate" v-if="carousel[current].type=='default'">
              <p :class="carousel[current].tagposition" class="BzObMp_Tag">
                {{carousel[current].tagline}}
              </p>
            </div>


          </div>
        </transition>
      </div>
      <div class="BzObMpCb_Navbar" draggable="false">
        <div v-for="(slide, index) in carousel" :key="index">
          <div class="BzObMpCbNb_Item" :class="{ active: actives[index].active }" @click="show_item(index)">
            {{slide.title}}
            <div class="BzObMpCbNbIt_Cursor"></div>
          </div>
          <div class="BzObMp_DottedDivider" v-if="index != carousel.length - 1"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'MainCarousel',
    props: {
      carousel: Array,
      animated: Boolean,
      animationDelay: Number
    },
    methods: {
      show_item: function (id) {
        this.animation = "zoom_f";

        if (id > this.current) {
          this.animation = "zoomr_f";
        }

        this.actives.forEach(element => {
          element.active = false;
        });
        this.current = id;
        this.actives[id].active = true;
      },
      animate: async function () {
        if (this.animated != null && this.animated) {
          let delay_local = 1000;
          if (this.animationDelay != null) {
            delay_local = this.animationDelay;
          }
          for (var i = 0; i < this.carousel.length; i++) {
            this.show_item(i);

            await new Promise(s => setTimeout(s, delay_local));
          }
          this.animate();
        }

      }
    },
    data() {
      return {
        actives: [],
        current: 0,
        animation: "zoomr_f"
      }

    },
    mounted() {
      this.actives = this.carousel;
      this.animate();
    }
  }
</script>

<style scoped>
  .BzObMp_TagParent {
    position: relative;
    width: 100%;
    padding: 80px 70px;
    height: 100%;
  }

  .BzObMp_Tag {
    color: white;
    font-style: italic;

    line-height: 4.7rem;
    font-size: 4.5rem;
    font-weight: 800;
    text-shadow: 0px 0px 7px rgba(0, 0, 0, 0.77);
  }

  .BzObMp_Tag.top-left {}

  .BzObMp_Tag.top-right {
    text-align: right;
  }

  .BzObMp_Tag.bottom-left {
    position: absolute;
    bottom: 80px;
    right: 70px;
    left: 70px;
  }

  .BzObMp_Tag.bottom-right {
    text-align: right;
    position: absolute;
    bottom: 80px;
    right: 70px;
    left: 70px;
  }

  .BzObMpCbMn_Image {
    width: 100%;
    height: calc(100% + 40px);
    margin-top: -20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .BzObMp_DottedDivider {
    height: 2px;
    width: calc(100% - 50px);
    opacity: 0.4;
    margin-left: 25px;

    background-image: url('../../assets/dashed_white.svg');
  }

  .BzObMp_CarouselBody {
    min-height: 542px;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 50px;
    display: flex;
    overflow: hidden;
  }

  .BzObMpCb_Main {
    border-bottom-left-radius: 50px;
    width: 100%;
    position: relative;
    overflow: hidden;
    -webkit-box-shadow: inset 0px 0px 35px -18px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: inset 0px 0px 35px -18px rgba(0, 0, 0, 0.75);
    box-shadow: inset 0px 0px 35px -18px rgba(0, 0, 0, 0.75);
    background: gray;
  }

  .BzObMpCb_Navbar {
    background: var(--PooltechnikOrangeDark);
    width: 260px;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 542px;

    flex-flow: wrap;
    justify-content: center;
  }

  .BzObMpCbNb_Item {
    font-size: var(--UIMajor);
    line-height: var(--UIMajor);
    font-weight: bold;
    color: white;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
    transition: all 0.2s;

    width: calc(100% + 10px);
    cursor: pointer;
  }

  .BzObMpCbNbIt_Cursor {
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    position: absolute;
    left: 0px;
    border-right: 30px solid transparent;
    transition: all 0.2s ease;
  }

  .BzObMpCbNb_Item.active .BzObMpCbNbIt_Cursor {
    left: -30px;
    border-right: 30px solid var(--PooltechnikOrangeDark);
  }

  .BzObMpCbNb_Item:hover .BzObMpCbNbIt_Cursor {
    left: -20px;
    border-right: 30px solid var(--PooltechnikOrangeDark);
  }

  .BzObMpCbNb_Item:hover {
    margin-left: 10px;
  }

  .BzObMpCbNb_Item.active:hover {
    margin-left: 0px;
  }

  .BzObMpCbNb_Item.active:hover .BzObMpCbNbIt_Cursor {
    left: -30px;
    border-right: 30px solid var(--PooltechnikOrangeDark);
  }
</style>