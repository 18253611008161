<template>
<div>
    <div class="main_login white white_stay">
        <div class="BzOb_LoginWindow">
            <div class="columns is-vcentered is-centered">
                <div class="column is-narrow">
                    <avatar username="Alena Vlasatá" :size="150"></avatar>
                </div>
                <div class="column is-narrow has-text-left">
                    <h1>Vítejte!</h1>
                    <h2>Aleno Vlasatá</h2>
                </div>
            </div>
        </div>
    </div>
    
</div>
</template>
<style>

</style>
<style scoped>
h1
{
    font-size: 2rem;
    color:white;
    margin-bottom: -15px;
}
h2
{
    font-size: 3rem;
    color:white;
    font-weight: bold;
}
.main_login
{
    position:absolute;
    display:flex;
    align-items: center;
    justify-content: center;
    height:100vh;
    width: 100vw;
    -webkit-animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation: slide-out-top 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
    animation-delay: 800ms;
    
    z-index:6201;
}
.bottom_buttons
{
    position:absolute;
    display:flex;
    justify-content: center;
    width: 100vw;
    bottom: 25px;
}
.BzOb_LoginWindow
{
    text-align: center;
    width: 200px;
    animation-delay: 600ms;
}


</style>
<script>
import Avatar from 'vue-avatar'
    export default {
        name: 'MainPage',
        title: "BazényObchod.cz",
        components: {
            Avatar
        },
        data() {
            return {
               
            }
        },
        computed: {
            sp_pad() {
                return this.$parent.get_sp_pad;
            }
        },
        methods: {

        },
        mounted() {
            this.$Progress.finish();
            this.$parent.logged = !this.$parent.logged;
            setTimeout(()=>this.$router.push('/'), 1800);
        }
    }
</script>