<template>
    <div>
        <div class="Sp_NoPad"></div>
        <div :class="sp_pad">
            <div class="columns">
                <div class="column is-narrow">
                    <CategoryList :navigate="true"></CategoryList>
                </div>
                <div class="column">
                    <MainCarousel :carousel="carousel" :animated="true" :animationDelay="3500" />
                    <br>
                    <div class="columns is-multiline is-variable is-4" v-if="products.length >= 1">
                        <div class="column is-one-third-widescreen is-half-tablet is-half-desktop PCard"
                            v-for="product in products" :key="product.id">
                            <section class="section1">
                                <ProductCard :product="product" v-if="product.dostupnost != '200'"
                                    @added_to_cart="$parent.add_to_cart" />
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <br><br><br><br><br><br><br>
        <NavigationFooter></NavigationFooter>
    </div>
</template>
<style scoped>

</style>
<script>
    import MainCarousel from "../components/uicomponents/MainCarousel.vue";
    import NavigationFooter from "../components/footer_components/NavigationFooter.vue";
    import CategoryList from "../components/navigation/CategoryList.vue";
    import ProductCard from "../components/ecommerce/ProductCard.vue";
    import axios from "axios";
    export default {
        name: 'MainPage',
        title: "BazényObchod.cz",
        components: {
            MainCarousel,
            NavigationFooter,
            CategoryList,
            ProductCard
        },
        data() {
            return {
                carousel_slides: [],
                products: [],
                carousel: [{
                        type: "default",
                        title: "Výprodej bazénů Marimex",
                        tagline: "Výprodej bazénů Marimex",
                        image: "https://www.avenberg.cz/static/_foto_zbozi/2/5/6/1/100ZA01024._._.o.jpeg",
                        tagposition: "top-left",
                        active: true
                    },
                    {
                        type: "default",
                        title: "NOVINKA! Mycí houby Azuro",
                        tagline: "NOVINKA! Mycí houby Azuro",
                        image: "https://picsum.photos/id/13/1920",

                        tagposition: "bottom-left",
                        active: false
                    },
                    {
                        type: "default",
                        title: "Vyhrajte bazénovou chemii Arcana",
                        tagline: "Vyhrajte bazénovou chemii Arcana",
                        image: "https://picsum.photos/id/28/1920",

                        tagposition: "top-right",
                        active: false
                    },
                    {
                        type: "default",
                        title: "Bazénové folie na míru",
                        tagline: "Bazénové folie na míru od babičky Dorotky",
                        image: "https://picsum.photos/id/27/1920",

                        tagposition: "bottom-right",
                        active: false
                    }
                ]
            }
        },
        computed: {
            sp_pad() {
                return this.$parent.get_sp_pad;
            }
        },
        methods: {
            preload_slides: function () {
                this.carousel.forEach((element, index) => {
                    this.carousel_slides[index] = new Image();
                    this.carousel_slides[index].src = element.image;
                });
            },
            load_random_products: function () {
                var self = this;
                axios.post("http://www.bazeny-obchod.cz/apieshop/produkty/1298", {
                        kategorie_info: '1',
                        strankovani: 3
                    })
                    .then(function (response) {

                        self.products = response.data.produkty;


                    })
                    .catch(function (error) {
                        console.log(error);
                    });


            }
        },
        mounted() {
            this.preload_slides();
            this.load_random_products();
            this.$Progress.finish();
        }
    }
</script>