<template>
  <div v-if="isOpen">
    <div class="BzObMm_Backdrop" :class="{closing: closing}"></div>
    <div class="BzObMm_Menu" :class="{closing: closing}" ref="mainmenubody">
      <div class="BzObMmMn_Close">
        <b-button size="is-large" class="is-hidden-mobile" type="is-text" icon-left="close" @click="close">
          
        </b-button>
        <b-button size="is-large" class="is-hidden-tablet" type="is-light" icon-left="close" @click="close">
          
        </b-button>
      </div>
      <div class="BzObMmMn_ImagePortion is-hidden-mobile">
        <div class="BzObMmMnIp_Center">
          <img src="../../assets/logo_white_f.svg" class="BzObMmMnIpCn_Image"/>
          <NavigationSearch theme="white" />
        </div>
      </div>
      <div class="BzObMmMn_MenuPortion">
        <div class="columns is-vcentered is-centered fh is-marginless">
          <div class="column is-one-third ScVw_Wrapper is-paddingless">
            <div class="ScrollView">
              <div class="ScVw_Padding is-hidden-mobile" v-bind:style="calculator.sp_spacer"></div>
              <div ref="menumainbody" class="ScVw_Menu" v-bind:style="calculator.sp_menu"> <CategoryList :navigate="true" @done="close"></CategoryList></div>
              <div class="ScVw_Padding is-hidden-mobile" v-bind:style="calculator.sp_spacer"></div>

            </div>
          </div>
          <div class="column is-one-fifth is-hidden-mobile"><div class="BzObMmMn_Vertical"></div></div>
          <div class="column is-one-quarter is-hidden-mobile">
            <div class="BzObMmMnMp_Dropdowns" v-for="link in links" :key="link.title">
              <b-collapse animation="slidfe" :open="openDropdown == link.title" @open="openDropdown = link.title">
                <template #trigger="props">
                  <div class="columns is-mobile is-vcentered">
                    <div class="column is-narrow BzObMmMnMpDp_Title">{{link.title}}</div>
                    <div class="column is-narrow ">
                      <b-icon size="is-big" :icon="props.open ? 'chevron-up' : 'chevron-down'">
                      </b-icon>
                    </div>
                  </div>
                </template>
                <ul class="BzObMmMnMpDp_Menu">
                  <li v-for="rlink in link.children" :key="rlink.title" @click="close">
                    <router-link :to="rlink.url" >{{rlink.title}}</router-link>
                  </li>
                </ul>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavigationSearch from '../navbar_components/NavigationSearch.vue'
import CategoryList from './CategoryList.vue'

  export default {
    name: 'MainMenu',
    components: {
      NavigationSearch, CategoryList
    },
    
    methods: {
      matchHeight: function () {
        var mainheight = this.$refs.mainmenubody.clientHeight;
        var menuheight = this.$refs.menumainbody.clientHeight;
        var heightString = ((mainheight - menuheight) / 2) + "px";
        this.calculator.sp_spacer = {
          height: heightString
        }
        this.calculator.sp_menu = {
          maxHeight: menuheight + "px"
        }
      },
      openMenu: function () {
        var width = window.clientHeight;
        if(width > 768)
        {

        setTimeout(() =>
          this.matchHeight(), 200);
        }
        
        this.isOpen = true
      },
      close: function() {
        this.closing = true;
        setTimeout(() =>

          this.isOpen = false, 1000);
        setTimeout(() =>
          this.closing = false, 1500);
      },
      closingMenu: function () {
        this.$emit('closingMenu');
      },
    },
  
    
    data() {
      return {
        calculator: {
          sp_spacer: {},
          sp_menu: {}
        },
        openDropdown: "",
        isOpen: false,
        closing: false,
        links: [
          {
            title: "O nákupu",
            children: [
              {
                title: "Koronavirus",
                url: "/"
              },
              {
                title: "Způsob platby",
                url: "/"
              },
              {
                title: "Dodání",
                url: "/"
              },
              {
                title: "Obchodní podmínky",
                url: "/"
              },
              {
                title: "Reklamační řád",
                url: "/"
              },
            ]
          },
          {
            title: "O nás",
            children: [
              {
                title: "O POOLTECHNIKu",
                url: "/"
              },
              {
                title: "Firemní stránky",
                url: "/"
              },
              {
                title: "Kontakty",
                url: "/"
              },
              {
                title: "Provozní doba",
                url: "/"
              },
              {
                title: "Spolupracujeme",
                url: "/"
              },
            ]
          },
          {
            title: "Můj účet",
            children: [
              {
                title: "Přihlásit se",
                url: "/"
              },
              {
                title: "Zapomenuté heslo",
                url: "/"
              },
              {
                title: "Registrace velkoobchodního partnera",
                url: "/"
              },
            ]
          },
          {
            title: "Poradna",
            children: [
              {
                title: "Stavba kruhového bazénu",
                url: "/"
              },
              {
                title: "Stavba nadzemního bazénu",
                url: "/"
              },
              {
                title: "Stavba ováleného bazénu",
                url: "/"
              },
              {
                title: "Problémy s bazénovou vodou",
                url: "/"
              },
            ]
          },
        ]
      }
    }
  }
</script>

<style scoped>
.BzObMmMn_Vertical
{
  background-image: url("../../assets/border_v.svg");
  height: calc(60vh);
  width:100%;
  background-position: center;
  background-repeat: no-repeat;
}
.ScVw_Menu
{
  overflow: scroll;
}
.BzObMm_Menu.closing
{
  animation-delay: 0s;
  -webkit-animation: scale-out-center 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  animation: scale-out-center 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}
.BzObMm_Backdrop.closing
{
  animation-delay: 0.3s!important;
  -webkit-animation: scale-out-vertical 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
  animation: scale-out-vertical 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}
.BzObMmMn_Close
{
  position: absolute;
  top: 20px;
  right: 30px;
}
@media screen and (max-width: 768px) {
  .BzObMmMn_Close
{
  position: absolute;
  top: 20px;
  right: calc(50% - 30px);
}
}
.ScVw_Padding
{
  
}
.ScVw_Wrapper
{
  height: 100%;
  position: relative;
}
.ScrollView
{
  width: 100%;
  position: absolute;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: scroll;
}
.BzObMmMnMpDp_Menu
{
  margin-top: 20px;
  margin-bottom: 20px;
}
.BzObMmMnMpDp_Title
{
  font-size: var(--UIMajor);
  font-weight: 900;
  color: var(--PooltechnikBlue);
  text-transform: uppercase
}
  .fh {
    height: 100%;
  }
.BzObMmMnIp_Center
{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.BzObMmMnIpCn_Image
{
  width: 100%;
}
.BzObMmMn_MenuPortion
{
  height: 100%;
  width: calc(100% - 33%);
  background-image: url("../../assets/bubbles.svg");
  background-position: bottom right;
  background-size: 180px;
  background-repeat: no-repeat;
}
.BzObMmMn_ImagePortion
{
  width: 33%;
  background-image: url("../../assets/mainmenu.jpg");
  background-position: right center;
  border-top-left-radius: 50px;
  position: relative;
}
.BzObMm_Backdrop {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.2);
  z-index: 9000;
  backdrop-filter: blur(10px);
  -webkit-animation: scale-up-ver-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-ver-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
.BzObMm_Menu {
  position: fixed;
  height: calc(100% - 100px);
  width: calc(100% - 100px);
  border-bottom-right-radius: 50px; 
  border-top-left-radius: 50px; 
  background-color: white;
  z-index: 9001;
  -webkit-animation: scale-in-center 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: scale-in-center 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation-delay: 0.2s;
  margin: 50px;
  display:flex;
}
@media screen and (max-width: 768px) {
  .BzObMm_Menu {
  position: fixed;
  height: calc(100% - 30px);
  width: calc(100% - 30px);
  border-bottom-right-radius: 5px; 
  border-top-left-radius: 5px; 
  margin: 15px;
  padding: 15px;
  flex-direction: column;
  overflow: scroll;
  padding-top: 100px;
}
.BzObMmMn_MenuPortion
{
  width: 100%;
}
.BzObMmMn_MenuPortion
{
  background: none;
}
.ScrollView
{
  width: 100%;
  position: relative;
  height: 100%;
  display: block;
  overflow: unset;
}
.ScVw_Padding
{
  display:none;
}
}
/* ----------------------------------------------
 * Generated by Animista on 2022-10-7 14:34:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-out-vertical
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-vertical {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
    opacity: 0;
  }
}

@keyframes scale-out-vertical {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
    opacity: 0;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-10-7 14:33:0
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-out-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
  }
}

@keyframes scale-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2022-10-7 14:18:3
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2022-10-7 13:6:9
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-ver-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-ver-center {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
}

@keyframes scale-up-ver-center {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
    opacity: 0;
  }

  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    opacity: 1;
  }
}
</style>