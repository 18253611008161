<template>
  <div class="BzOb_Account">
    <img src="../../assets/menu_base.svg" v-if="theme == 'base'" @click="menu_opening"/>
    <img src="../../assets/menu_white.svg" v-if="theme == 'white'" @click="menu_opening"/>
  </div>
</template>

<script>
export default {
  name: 'NavigationMenu',
  props: {
    theme: String
  },
  methods: {
    menu_opening: function () {
     
      this.$emit('menu_opening');
    }
  }
}
</script>

<style scoped>
img,.BzOb_Account {
  height: 48px;
  cursor: pointer;
}
   @media screen and (max-width:768px) {
  img,.BzOb_Account {
  height: 36px;
  cursor: pointer;
}
}
</style>