<template>
  <div>
    <div v-for="category in categories" :key="category.id">
      <b-collapse class="BzObPcCd_Filter" v-if="category.podkategorie" animation="slide"
        :open="isOpenMenu == category.nazev" @open="isOpenMenu = category.nazev">
        <template #trigger="props">
          <div class="pseudobtn lvlN" :class="{active:(current_category == category.nazev)}" @mouseover="load_subcategories(category.id);" @click="clickcat(category.url, isOpenMenu, category.nazev, category.id,0)">
            <div class="columns is-gapless is-mobile is-vcentered">
              <div class="column is-narrow menubutton-icon-wrapper pseudobuttonique">
                <img :src="require('../../assets/category_icons/'+category.url+'.svg')" class="menubutton-icon" />
              </div>
              <div class="column">
                {{category.nazev}}
              </div>
              <div class="column is-narrow">
                <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon>
              </div>
            </div>
          </div>



        </template>
        <div v-for="subcategory1 in subcategories_menu['c'+category.id]" :key="subcategory1.id">
          <b-collapse class="BzObPcCd_Filter lvl1" v-if="subcategory1.podkategorie" animation="slide"
            :open="isOpenMenu1 == subcategory1.nazev" @open="isOpenMenu1 = subcategory1.nazev">
            <template #trigger="props">
              <div class="pseudobtns" @mouseover="load_subcategories(subcategory1.id);" @click="clickcat(subcategory1.url, isOpenMenu1, subcategory1.nazev, subcategory1.id,1)">

                <div class="pseudobtn has-backdrop  lvl1 " :class="{active:(current_category == subcategory1.nazev)}">
                  <div class="columns is-gapless is-mobile is-vcentered">

                    <div class="column">
                      {{subcategory1.nazev}}
                    </div>
                    <div class="column is-narrow" v-if="subcategory1.podkategorie">
                      <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'" class="has-text-primary"></b-icon>
                    </div>
                  </div>
                </div>
                <div class="pseudobtn_backdrop lvl1"></div>
              </div>



            </template>
            <div v-for="subcategory2 in subcategories_menu['c'+subcategory1.id]" :key="subcategory2.id">
              <b-collapse class="BzObPcCd_Filter lvl2" v-if="subcategory2.podkategorie" animation="slide"
                :open="isOpenMenu2 == subcategory2.nazev" @open="isOpenMenu2 = subcategory2.nazev">
                <template #trigger="props">
                  <div class="pseudobtns" @mouseover="load_subcategories(subcategory2.id)">

                    <div class="pseudobtn has-backdrop lvl2" :class="{active:(current_category == subcategory2.nazev)}">
                      <div class="columns is-gapless is-mobile is-vcentered">

                        <div class="column">
                          {{subcategory2.nazev}}
                        </div>
                        <div class="column is-narrow" v-if="subcategory2.podkategorie">
                          <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'" class="has-text-link"></b-icon>
                        </div>
                      </div>
                    </div>
                    <div class="pseudobtn_backdrop lvl2"></div>
                  </div>



                </template>
                <div v-for="subcategory3 in subcategories_menu['c'+subcategory2.id]" :key="subcategory3.id">
                  <b-collapse class="BzObPcCd_Filter lvl3" v-if="subcategory3.podkategorie" animation="slide"
                    :open="isOpenMenu3 == subcategory3.nazev" @open="isOpenMenu3 = subcategory3.nazev">
                    <template #trigger="props">
                      <div class="pseudobtns" @mouseover="load_subcategories(subcategory3.id)">

                        <div class="pseudobtn has-backdrop lvl3" :class="{active:(current_category == subcategory3.nazev)}">
                          <div class="columns is-gapless is-mobile is-vcentered">

                            <div class="column has-text-italic">
                              {{subcategory3.nazev}}
                            </div>
                            <div class="column is-narrow" v-if="subcategory3.podkategorie">
                              <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'" class="has-text-black">
                              </b-icon>
                            </div>
                          </div>
                        </div>
                        <div class="pseudobtn_backdrop lvl3"></div>
                      </div>



                    </template>
                    <div @click="clickcat(subcategory4.url, true, true, subcategory4.id,4)" class="pseudobtn lvl4" :class="{active:(current_category == subcategory4.nazev)}" v-for="subcategory4 in subcategories_menu['c'+subcategory3.id]" :key="subcategory4.id">
                      <div class="columns is-gapless is-mobile is-vcentered">
                    
                        <div class="column is-italic">
                          {{subcategory4.nazev}}
                        </div>
                        <div class="column is-narrow" v-if="subcategory4.podkategorie">
                          <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'" class="has-text-black">
                          </b-icon>
                        </div>
                      </div>
                    </div>
                    
                  </b-collapse>
                  <div @click="clickcat(subcategory3.url, true, true, subcategory3.id,3)" class="pseudobtns lvl3" v-else>

                    <div class="pseudobtn has-backdrop lvl3" :class="{active:(current_category == subcategory3.nazev)}">
                      <div class="columns is-gapless is-mobile is-vcentered">

                        <div class="column has-text-italic">
                          {{subcategory3.nazev}}
                        </div>

                      </div>
                    </div>
                    <div class="pseudobtn_backdrop lvl3"></div>
                  </div>
                </div>

              </b-collapse>
              <div @click="clickcat(subcategory2.url, true, true, subcategory2.id,2)" class="pseudobtns lvl2" v-else>

                <div class="pseudobtn has-backdrop lvl2" :class="{active:(current_category == subcategory2.nazev)}">
                  <div class="columns is-gapless is-mobile is-vcentered">

                    <div class="column">
                      {{subcategory2.nazev}}
                    </div>

                  </div>
                </div>
                <div class="pseudobtn_backdrop lvl2"></div>
              </div>
            </div>

          </b-collapse>
          <div @click="clickcat(subcategory1.url, true, true, subcategory1.id,1)" class="pseudobtns lvl1" v-else>

            <div class="pseudobtn has-backdrop lvl1" :class="{active:(current_category == subcategory1.nazev)}">
              <div class="columns is-gapless is-mobile is-vcentered">

                <div class="column">
                  {{subcategory1.nazev}}
                </div>

              </div>
            </div>
            <div class="pseudobtn_backdrop lvl1"></div>
          </div>
        </div>

      </b-collapse>
      <div @click="clickcat(category.url, true, true, category.id,0)" class="pseudobtn lvl0"  :class="{active:(current_category == category.nazev)}" v-else>
        <div class="columns is-gapless is-mobile is-vcentered">
          <div class="column is-narrow menubutton-icon-wrapper pseudobuttonique">
            <img :src="require('../../assets/category_icons/'+category.url+'.svg')" class="menubutton-icon" />
          </div>
          <div class="column">
            {{category.nazev}}
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  export default {
    name: 'CategoryList',
    props: {
      navigate: Boolean,
      current_category: [String, Number],
      level: Number
    },
    methods: {
      clickcat: function(url, condition1, condition2, uid, level)
      {
        localStorage.setItem("l"+level,condition2);
        localStorage.setItem("d"+level,uid);
        if (condition1 == condition2) {
          if(this.navigate)
          {
              this.$router.push({ path: url });
          }
          else
          {
            this.load_category_by_uid(uid);
          }
          this.done();
        }
      },
      load_category_by_uid: function (uid) {
        this.$emit("selected", uid);
      },
      done: function () {
        this.$emit("done");
      },
      load_subcategories: function(id)
      {
        if(id != null)
        {
          if (!(("c" + id) in this.subcategories_menu))
          {
            var self = this;
            var url = "https://www.bazeny-obchod.cz/apieshop/podkategorie/" + id;
            axios.get(url)
              .then(function (response) {
                self.$set(self.subcategories_menu, "c" + id, response.data.kategorie);
              })
              .catch(function (error) {
                self.$buefy.snackbar.open({
                  duration: 5000,
                  message: "Stala se chyba v načítání podkategorií: " + error,
                  type: 'is-danger',
                  position: 'is-bottom-left',
                  queue: false,

                })
              });
          }
        }
        
        
      }
    },
    created() {
      
      if(this.current_category != null)
      {
        var self = this;
        setTimeout(function(){
              self.load_subcategories(localStorage.getItem("d0"));
              self.load_subcategories(localStorage.getItem("d1"));
              self.load_subcategories(localStorage.getItem("d2"));
              self.load_subcategories(localStorage.getItem("d3"));
        }, 500);
        setTimeout(function(){
               self.isOpenMenu = localStorage.getItem("l0");
              self.isOpenMenu1 = localStorage.getItem("l1");
              self.isOpenMenu2 = localStorage.getItem("l2");
              self.isOpenMenu3 = localStorage.getItem("l3");
              self.isOpenMenu4 = localStorage.getItem("l4");
        }, 100);
        
      }
    },
    watch: {
      current_category: function(now) {
        var self = this;
        console.log(now);
       setTimeout(function(){
              self.isOpenMenu = localStorage.getItem("l0");
              self.isOpenMenu1 = localStorage.getItem("l1");
              self.isOpenMenu2 = localStorage.getItem("l2");
              self.isOpenMenu3 = localStorage.getItem("l3");
              self.isOpenMenu4 = localStorage.getItem("l4");
        }, 1500);
        
      }
    },
    data() {
      return {
        isOpenMenu: "",
        isOpenMenu1: "",
        isOpenMenu2: "",
        isOpenMenu3: "",
        isOpenMenu4: "",
        subcategories_menu: {
        },
        categories: [{
          id: "1189",
          nazev: "Bazény, příslušenství, stavba",
          img: "",
          url: "bazeny-prislusenstvi-stavba",
          podkategorie: true
        }, {
          id: "1134",
          nazev: "Mobilní nafukovací vířivky",
          img: "",
          url: "nafukovaci-virivky",
          podkategorie: true
        }, {
          id: "1138",
          nazev: "Infrasauny a doplňky",
          img: "",
          url: "infrasauny-prislusenstvi",
          podkategorie: true
        }, {
          id: "1186",
          nazev: "Finské sauny, doplňky",
          img: "",
          url: "finske-sauny-doplnky",
          podkategorie: true
        }, {
          id: "1281",
          nazev: "Domácí fitness",
          img: "",
          url: "domaci-fitness",
          podkategorie: true
        }, {
          id: "1297",
          nazev: "Koloběžky",
          img: "",
          url: "kolobezky",
          podkategorie: true
        }, {
          id: "1162",
          nazev: "Trampolíny",
          img: "",
          url: "trampoliny",
          podkategorie: true
        }, {
          id: "1163",
          nazev: "Zahradní nábytek",
          img: "",
          url: "zahradni-nabytek",
          podkategorie: true
        }, {
          id: "1315",
          nazev: "Aroma Difuzéry",
          img: "",
          url: "aroma-difuzery",
          podkategorie: false
        }, {
          id: "1305",
          nazev: "Dezinfekce prostor",
          img: "",
          url: "prostorova-dezinfekce",
          podkategorie: true
        }, {
          id: "1351",
          nazev: "Fontány",
          img: "",
          url: "fontany",
          podkategorie: false
        }, {
          id: "1183",
          nazev: "Zahradní domky",
          img: "",
          url: "zahradni-domky",
          podkategorie: true
        }],
      }
    }
  }
</script>

<style scoped>

.pseudobtn.lvl0 {
    margin-bottom: 15px !important;
  }
.pseudobtn.lvl4 {
  margin-bottom: 15px !important;
  margin-top: 10px;
    margin-left: 15px;
}
  .pseudobtns.lvl1 {
    padding-left: 15px;
    margin-bottom: 15px !important;
    width: calc(100% - 15px);
  }

  .pseudobtns.lvl2,
  .pseudobtns.lvl3 {
    padding-left: 15px;
    margin-top: 10px;
    margin-bottom: 15px !important;
    width: calc(100% - 15px);
  }

  .BzObPcCd_Filter.lvl1 {
    padding-left: 15px;
  }

  .BzObPcCd_Filter.lvl2 {
    margin-top: 10px;
    padding-left: 15px;
  }

  .BzObPcCd_Filter.lvl3 {
    margin-top: 10px;
    padding-left: 15px;
  }

  .BzObPcCd_Filter {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 15px;
  }

  .BzObPcCd_FilterBorder {
    height: 10px;
    width: 100%;
    background-image: url("../../assets/border.svg");
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 10px;
    margin-bottom: 10px;
  }
.pseudobtn {
  background-color: #E4E4E4;
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-size: 1rem;
  height: 2.5em;
  line-height: 1.6;
  border-radius: 0px !important;
  border-top-left-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  border-width: 2pt !important;
  text-align: left !important;
  font-weight: bold;
  margin-bottom: 10px;
  justify-content: unset !important;
}

.pseudobtn:hover {
  background-color: #dedede;
}

.pseudobtn:active {
  background-color: #d7d7d7;
}

.menubutton-icon-wrapper.pseudobuttonique {
  margin-right: 10px;
}

  .pseudobtn {
    background-color: #E4E4E4;
    color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    padding-bottom: calc(0.5em - 1px);
    padding-left: 1em;
    padding-right: 1em;
    padding-top: calc(0.5em - 1px);
    white-space: wrap;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    font-size: 1rem;
    min-height: 2.5em;
    line-height: 1.6;
    border-radius: 0px !important;
    border-top-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    border-width: 2pt !important;
    text-align: left !important;
    font-weight: bold;
    margin-bottom: 10px!important;
    justify-content: unset !important;
    transition: background-color .3s ease;
  }
  .pseudobtn.lvl0.active,.pseudobtn.lvlN.active {
  background-color: #d3d3d3!important
}
.pseudobtn.lvl1.active {
  background-color: var(--PooltechnikBlueLight)!important
}
.pseudobtn.lvl2.active {
  background-color: var(--PooltechnikOrangeLight)!important
}
.pseudobtn.lvl3.active{
  background-color: #4e4e4e!important;
  color: white
}
  .pseudobtn_backdrop {
    position: absolute;
    width: 20px;
    height: 100%;
    border-top-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
  }

  .pseudobtn.has-backdrop {
    margin-left: 5px;
    z-index: 200;
    position: absolute;
    width: calc(100% - 5px);
  }

  .pseudobtns {
    position: relative;
    width: 100%;
    min-height: 2.5em;
  }

  .pseudobtn_backdrop.lvl1 {
    background-color: var(--PooltechnikBlue)
  }

  .pseudobtn_backdrop.lvl2 {
    background-color: var(--PooltechnikOrange)
  }

  .pseudobtn_backdrop.lvl3 {
    background-color: black
  }

  .pseudobtn_backdrop.lvl4 {
    background-color: white;
  }

  .pseudobtn:hover {
    background-color: #dedede;
  }

  .pseudobtn:active {
    background-color: #d7d7d7;
  }

.pseudobuttonique {
    margin-right: 10px!important;
  }
</style>