<template>
  <router-link tag="div" to="/" class="BzOb_Logo">
    <div class="logoparent u" :class="{collapsed: collapsed}">
      <img class=" lf" src="../../assets/logo_base_c.svg" v-if="type == 'path' && theme == 'base'"/>
      <img class="lf" src="../../assets/logo_base_f.svg" v-if="type == 'bare' && theme == 'base'" />
      <img class="lf" src="../../assets/logo_white_c.svg" v-if="type == 'path' && theme == 'white'"/>
      <img class="lf" src="../../assets/logo_white_f.svg" v-if="type == 'bare' && theme == 'white'" />
      <img class="is-hidden lm" src="../../assets/logo_mobile.svg" v-if="type == 'path' && theme == 'base'" />
      
      <img class="is-hidden lm" src="../../assets/logo_mobile_white.svg" v-if="type == 'path' && theme == 'white'" />
      <img class="is-hidden lm" src="../../assets/logo_mobile_white.svg" v-if="type == 'bare' && theme == 'white'" />
    </div>
    <div class="logoparent c" :class="{collapsed: !collapsed}">
      <img class="lc" src="../../assets/logo_collapsed_c.svg" v-if="theme == 'base'" />
      <img class="lc" src="../../assets/logo_collapsed_w.svg" v-if="theme == 'white'" />
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'NavigationLogo',
  props: {
    type: String,
    theme: String, 
    collapsed: Boolean
  }
}
</script>

<style scoped>
.BzOb_Logo {
  cursor: pointer;
  display: flex;
}
.logoparent {
  transition: all 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955);
  
}
.logoparent.u {
  width: 300px;
}
.logoparent.c {
  width: 150px;
}
.logoparent.collapsed {
  width: 0px;
  opacity: 0%;
  transition: all 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955);
}
.lf {
  width: 300px;
  height: 70px;
  margin-top: 0px;
  margin-bottom: -7px;
}
.lc {
    height: 70px;width: 150px;
    margin-top: 0px;
    margin-bottom: -7px;
}
.lm
{
  width: 130px;
    margin-top: -7px;
}

@media screen and (max-width: 768px) {
  .lf {
  width: 180px;
  height: auto;
  margin-top: 12px;
  margin-bottom: 0px;
}
.logoparent.u {
  width: unset;
}
}
</style>
