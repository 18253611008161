<template>
  <div :class="theme == 'Bz_Bottom white' ? 'is-hidden ': 'Bz_Bottom'">
    <div :class="scrolling ? 'scrolling back': ''">
      <div class="BzOb_NavigationBar">
        <div class="columns is-vcentered is-mobile is-centered BzObNb_Cols" :class="scrolling ? 'scrolling': ''">
          <div class="column has-text-centered is-one-quarter">
            <NavigationMenu :logged="logged" :theme="theme" @menu_opening="menu_opening" />
            <p class="BzObNavMob_Title">Menu</p>
          </div>
          <div class="column has-text-centered is-one-quarter">
            <NavigationLove :windowWidth="window_width" :theme="theme" />
            <p class="BzObNavMob_Title">Oblíbené</p>
          </div>
          <div class="column has-text-centered is-one-quarter">
            <NavigationCompare :windowWidth="window_width" :theme="theme" />
            <p class="BzObNavMob_Title">Porovnání</p>
          </div>
          <div class="column has-text-centered is-one-quarter">
            <NavigationCart :theme="theme" :cart="cart" />
            <p class="BzObNavMob_Title">Košík</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import NavigationCart from '../navbar_components/NavigationCart.vue'
  import NavigationCompare from '../navbar_components/NavigationCompare.vue'
  import NavigationLove from '../navbar_components/NavigationLove.vue'
  import NavigationMenu from '../navbar_components/NavigationMenu.vue'

  export default {
    name: 'NavigationBar',
    props: {
      theme: String,
      type: String,
      window_width: Number,
      cart: [Object, Array],
      path: [Object, Array]
    },
    components: {
      NavigationCart,
      NavigationCompare,
      NavigationLove,
      NavigationMenu
    },
    data() {
      return {
        scrolling: false,
        collapsed: false,

      }
    },
    created() {
      this.handleScroll();
      window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
      window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
      process: function () {
        this.collapsed = true;
      },
      handleScroll() {
        if (window.scrollY >= 100) {
          this.scrolling = true;
        } else {
          this.scrolling = false;
        }
      },
      menu_opening: function () {

        this.$emit('menu_opening');
      },
    }
  }
</script>

<style scoped>
.BzObNavMob_Title
{
  font-size: 14px;
  color: var(--PooltechnikBlue);
  font-weight: 500;
  opacity: .8
}
.column
{
  align-items: center;
}
  .BzObNb_Cols {

    padding-top: 10px;
    padding-bottom: 10px;

    margin-left: 0px;
    margin-right: 0px;
    transition: all 0.3s ease;
  }

  .BzOb_NavigationBar {
    margin: auto;
    max-width: 1490px;
  }

  .Bz_Bottom {
    position: fixed;
    width: calc(100%);
    z-index: 9000;
    bottom: 0;
    background: rgb(252, 252, 252);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    -webkit-box-shadow: 0px 0px 53px -30px rgba(0,0,0,0.49);
-moz-box-shadow: 0px 0px 53px -30px rgba(0,0,0,0.49);
box-shadow: 0px 0px 53px -30px rgba(0,0,0,0.49);
  }

  @-webkit-keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-10px);
      transform: translateY(-10px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @-webkit-keyframes scale-in-hor-center {
    0% {
      -webkit-transform: scaleX(0.6);
      transform: scaleX(0.6);
      opacity: 0;
    }

    100% {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      opacity: 1;
    }
  }

  @keyframes scale-in-hor-center {
    0% {
      -webkit-transform: scaleX(0.6);
      transform: scaleX(0.6);
      opacity: 0;
    }

    100% {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
      opacity: 1;
    }
  }
</style>