<template>
  <router-link tag="div" to="/kosik" class="BzOb_Cart noselect" :style="cssProps">
    <div class="BzObCa_Icon">
      
      <img src="../../assets/cart_white.svg" v-if="(items_size == 0) && theme == 'white'" />
      <img src="../../assets/cart_white_n.svg" v-if="(items_size > 0 && items_size < 10) && theme == 'white'" />
      <img src="../../assets/cart_white_m.svg" v-if="(items_size >= 10) && theme == 'white'" />
      <img src="../../assets/cart_base.svg" v-if="(items_size == 0) && theme == 'base'" />
      <img src="../../assets/cart_base_n.svg" v-if="(items_size > 0 && items_size < 10) && theme == 'base'" />
      <img src="../../assets/cart_base_m.svg" v-if="(items_size >= 10) && theme == 'base'" />
      
      <p class="BzObLd_Counter1 noselect" v-if="items_size > 0 && items_size < 10"><transition name="zoomr_f" mode="out-in"><div :key="products_c">{{products_c}}</div></transition></p>
      <p class="BzObLd_Counter2 noselect" v-if="items_size >= 10">{{products_c}}</p>
    </div>
    <div class="BzObIl_Text is-hidden-desktop-only is-hidden-tablet-only is-hidden-mobile" :class="{collapsed: !(items_size > 0)}">
      <p class="BzObIlTx_1 noselect"><transition name="zoomr_f" mode="out-in"><div :key="products_in_cart">{{products_in_cart}}</div></transition></p>
      
      <p class="BzObIlTx_2 noselect" ><transition name="zoomr_f" mode="out-in"><div :key="products_in_cart">{{new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }).format(items_price)}}</div></transition></p>
   
      
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'NavigationCart',
  props: {
    theme: String,
    cart: [Object, Array]
  },
  data() {
    return {
      price: "5 000 Kč"
    }
  },
  methods: {
    get_cart_products: function () {

    }
  },
  computed: {
    items_price() {
                var total = 0;
                var arr = this.cart.items;
                for(var i = 0; i < arr.length; i++)
                {
                    total += (arr[i].cart_item_array.cena * arr[i].cart_item_amount)
                }
                
                return total;
            },
             items_size() {
      var total = 0;
      var arr = this.cart.items;
      for(var i = 0; i < arr.length; i++)
      {
          total += arr[i].cart_item_amount
      }
      
      return total;
    },
    products_c() {
      if (this.items_size < 99)
        return this.items_size;
      else
        return "+99";
    },
    products_in_cart() {
      if(this.items_size == 0)
        return "Super!";

      if(this.items_size == 1)
        return "1 produkt";

      if(this.items_size >=2 && this.items_size <=4)
        return this.items_size + " produkty";

      return this.items_size + " produktů";
    },
    cssProps() {
      if (this.theme == "base") {
        return { '--text': "black" }
      }
      else {
        return { '--text': "white" }
      }
    }
  }
}
</script>

<style scoped>
img {
  height: 48px;
}
.BzOb_Cart {
  display: flex;
  height: 48px;
  cursor: pointer;
}
.BzObCa_Icon {
  width: 48px;
  position: relative;
}

.BzObLd_Counter1 {
  position: absolute;
  font-weight: 900;
  top: -0.7px;
  right: -0.5px;
  font-size: 14px;
  color: white;
  text-align: center;
  width: 18.7px;
  height: 18.7px;
}

.BzObLd_Counter2 {
  position: absolute;
  font-weight: 900;
  top: -0.7px;
  right: -0.5px;
  font-size: 14px;
  color: white;
  text-align: center;
  width: 26px;
  height: 18.7px;
}
@media screen and (max-width:768px) {
  img {
  height: 36px;
}
.BzOb_Cart {
  display: flex;
  height: 36px;
  cursor: pointer;
  flex-flow: column;
    align-items: center;
    width: 100%;
}
.BzObCa_Icon {
  width: 36px;
  position: relative;
}
.BzObLd_Counter1 {
  font-size: 10px;
  text-align: center;
    top: .2px;
  right: -2px;
}

}
.BzObIlTx_1 {
  font-size: var(--UICompletor);
  color: var(--text);
  margin-top: 2px;
  overflow: hidden;

  pointer-events: none;
  margin-bottom: -10px;
}

.BzObIlTx_2 {
  font-size: var(--UIMajor);
  margin-top: 0px;
  overflow: hidden;

  font-weight: 900;
  color: var(--text);
}

.BzObIl_Text {
  margin-left: 10px;
  width: 100px;
  opacity: 1;
  overflow: hidden;
  min-width: 100px;
  transition: all 0.5s ease;
}
.BzObIl_Text.collapsed
{
  margin-left: 0px;
  min-width: 0px;
  width: 0px;
  opacity: 0;
}
</style>